<template>
    <div class="ma-5 symmetry_blue">
        <!--<q-my-profile-submenu></q-my-profile-submenu>-->
        <h3><span class="first-with-short-rule">Activate Single Sign On (SSO) by connecting your accounts</span></h3>
        <v-container>
            <v-row no-gutters wrap class="d-flex py-1" v-for="(item, key) in linkables" :key="key">
                <v-col cols=" 12" sm="1">
                    <v-card class="py-2" tile outlined :color="item.color" align="center" justify="center" height="100%">
                        <span v-if="item.loading">
                            <v-progress-circular small color="white" indeterminate></v-progress-circular>
                        </span>
                        <span v-else>
                            <v-icon left v-if="isLinked(item.connection)" color="white" large>fas fa-check</v-icon>
                            <v-icon left v-else color="white" large>fas fa-exclamation-triangle</v-icon>
                        </span>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="7">
                    <v-card class="pt-3" tile flat :color="item.color" height="100%">
                        <h4 class="text-center text-md-left white--text">{{item.title}} </h4>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-card class="pa-1" tile flat :color="item.color" align="center" justify="center" height="100%">
                        <v-btn color="white" depressed v-if="!isLinked(item.connection)" @click="beginAccountLinking(item)">
                            Connect
                            <v-icon small right>fas fa-external-link-alt</v-icon>
                        </v-btn>
                        <h5 v-else class="pt-2 lighest_grey--text"> Connected <v-btn v-if="false" @click="unlinkAccount(item.connection)" dark outlined color="white" x-small>
                                Un-Link
                            </v-btn>
                        </h5>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!--
        <v-card-actions v-if=" !item.loading">
            <v-btn @click="unlinkAccount(item.connection)" dark color=" grey lighten-1" v-else x-small>
                            Un-Link
                        </v-btn>
        </v-card-actions>
        //-->
        </v-col>
        </v-row>
    </div>
</template>
<script>
import auth0 from 'auth0-js';
import axios from "axios";
const request = require("superagent");
import QMyProfileSubmenu from '@/components/navigation/Submenus/QMyProfileSubmenu.vue';

export default {
    data: function() {
        return {
            webAuth: {},
            primary_token: null,
            sub: this.$auth.user.sub,
            identities: null,
            linkables: [{
                    title: "Onboarding Portal",
                    connection: 'symmetryonboarding-com',
                    color: 'q_agency_1',
                    loading: true,
                    client_id: 'm0Red8ZZK6hpdZ2BQ66nkl0vDKM4HMA1'
                },

                /*
                                {
                                    title: "SymmetryU",
                                    connection: 'tba',
                                    color: 'q_agency_1',
                                    loading: true,
                                    client_id: 'FDrEkjEz5P2twQ5LBIwcYlBOU4nLVXsA'
                                },*/
            ]
        }
    },
    mounted: function() {
        this.sub = this.$auth.auth_user.sub
        var g = this
        //create new auth0 ibject with proper scopes for linking
        var opts = {
            audience: process.env.VUE_APP_AUTH0_MANAGEMENT_AUDIENCE,
            scope: 'openid email profile scope: read:current_user update:current_user_identities create:current_user_metadata update:current_user_metadata delete:current_user_metadata',
            redirect_uri: process.env.VUE_APP_BASE_URL + '/admin/linkaccounts'
        };
        this.$auth.getTokenSilently(opts).then(function(token) {
            g.primary_token = token;
            g.getLinkedAccounts(token);
        })
        //simulate linking animation.
    },
    components: {
        QMyProfileSubmenu
    },
    methods: {
        'getLinkedAccounts': function(token) {
            var g = this
            var auth0Manage = new auth0.Management({
                domain: process.env.VUE_APP_AUTH0_DOMAIN,
                token: token
            });
            auth0Manage.getUser(g.sub, function(err, auth) {
                if (err) {
                    g.showError(err);
                } else {
                    g.identities = auth.identities
                    g.simulateLinking();
                }
            });
        },
        'isLinked': function(connection) {
            if (this.identities == null) {
                return false;
            }
            return this.getIdentity(connection) != null;
        },
        getIdentity: function(connection) {
            for (var i = 0; i < this.identities.length; i++) {
                if (this.identities[i].connection == connection) {
                    return this.identities[i]
                }
            }
            return null;
        },
        //user clicked the link button. 
        'beginAccountLinking': function(connection) {
            var primary_token = this.primary_token
            var sub = this.sub
            var g = this;
            //localStorage.setItem("linking", "linking");
            console.log(connection.client_id)
            this.webAuth = new auth0.WebAuth({
                domain: process.env.VUE_APP_AUTH0_DOMAIN2,
                clientID: connection.client_id
                //clientID: process.env.VUE_APP_AUTH0_CLIENT_ID
            });
            this.webAuth.authorize({
                audience: process.env.VUE_APP_AUTH0_AUDIENCE,
                prompt: 'login',
                scope: 'openid profile',
                redirectUri: process.env.VUE_APP_BASE_URL + '/admin/linkaccount',
                responseType: 'id_token token code',
                connection: connection.connection,
            }, function() {});
            //when this returns to the redirect Uri, the primary user is logged out and the secondaryUser is logged in. Cannot access the primary id's anymore. 
        },
        unlinkAccount(connection) {
            var g = this
            var id = this.getIdentity(connection);
            request
                .del('https://' + process.env.VUE_APP_AUTH0_DOMAIN2 + '/api/v2/users/' + this.sub +
                    '/identities/' + id.connection + '/' + id.user_id, )
                .set('Authorization', 'Bearer ' + this.primary_token)
                .then(function(result) {
                    g.getLinkedAccounts()
                })
                .catch(function(err) {
                    g.showError("Problem unlinking accounts.")
                });
        },
        simulateLinking() {
            var g = this;
            var c = 0
            for (var i = 0; i < g.linkables.length; i++) {
                setTimeout(function() {
                    g.linkables[c].loading = false;
                    c++;
                }, i * 250)
            }
        }
    }
}

</script>
